import React, { useContext, useState } from 'react';
import { FieldArray } from 'formik';
import { isEmpty, has } from 'lodash';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '../../../../style/utils/Grid.styled';
import { ExperianceDetailsInit } from '../statistics/professionalDetailsInitialValues';
import { FormContentInputDiv, FormWrap, FromContent } from '../../../../style/components/form/StyledForm.styled';
import ErrorMessage from '../../../components/message/ErrorMessage';
import CustomTextField from '../../../components/form/formControl/TextField';
import { deleteExperience } from '../../../../api/apis';
import showSnackbar from '../../../components/message/showSnackbar';
import { MyContext } from '../../../context/Context';
import { CloudUpload, InsertDriveFile, PictureAsPdf } from '@mui/icons-material';
import { type } from '@testing-library/user-event/dist/type';
import formatFileName from '../../../../func/formatFileName';

const experianceDetailsSection = () => {
  // State to control the visibility of the delete confirmation dialog
  const [expDelDiaOpen, setExpDelDiaOpen] = useState(false);

  // State to store the ID of the experience to be deleted
  const [delExpId, setDelExpId] = useState(null);

  // Extracting the getProfessionalDetails function from the context
  const { getProfessionalDetails } = useContext(MyContext);

  // Function to handle the initiation of the delete process
  const handleDelExp = (id) => {
    // Set the ID of the experience to be deleted
    setDelExpId(id);
    // Open the delete confirmation dialog
    setExpDelDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelExpConfirm = async () => {
    // Call the API to delete the experience
    const apiRes = await deleteExperience(delExpId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setExpDelDiaOpen(false);
      // Refresh the professional details
      getProfessionalDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
  };

  // Function to handle the cancellation of the delete action
  const handleDelExpCancel = () => {
    // Close the delete confirmation dialog without deleting
    setExpDelDiaOpen(false);
  };

  const handleImagePreview = (name) => {
    const createObjUrl = URL.createObjectURL(name);
    window.open(createObjUrl, '_blank');
  }

  return (
    <>
      <FieldArray name="experianceDetails">
        {(props) => {
          const { remove, push, form } = props;
          const {
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            errors,
            touched,
            setFieldTouched
          } = form;
          const { experianceDetails } = values;
          const getFileIcon = (file) => {
            if (file?.type === 'application/pdf') {
              return <PictureAsPdf />;
            }
            if (file?.type?.includes('image')) {
              return <ImageIcon />;
            }
            return <InsertDriveFile />;
          };

          return (
            <Grid gap={'1rem'}>
              {experianceDetails.length > 0
                ? experianceDetails?.map((add, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details </Typography>
                        {experianceDetails.length !== 1 && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              const expId = experianceDetails[index]?.id;
                              if (expId) {
                                handleDelExp(expId);
                              } else {
                                remove(index);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </CardActions>
                      <CardContent>
                        <FromContent>
                          <CustomTextField
                            label="Company Name"
                            name={`experianceDetails.${index}.companyName`}
                            value={experianceDetails[index].companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={
                              has(errors, `experianceDetails.${index}.companyName`) &&
                                touched.experianceDetails?.[index]?.companyName ?
                                errors.experianceDetails[index].companyName : ''
                            }
                            touched={touched.experianceDetails?.[index]?.companyName}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="Job Title"
                            name={`experianceDetails.${index}.jobTitle`}
                            value={experianceDetails[index].jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={
                              has(errors, `experianceDetails.${index}.jobTitle`) &&
                                touched.experianceDetails?.[index]?.jobTitle ?
                                errors.experianceDetails[index].jobTitle : ''
                            }
                            touched={touched.experianceDetails?.[index]?.jobTitle}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="City"
                            name={`experianceDetails.${index}.city`}
                            value={experianceDetails[index].city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={
                              has(errors, `experianceDetails.${index}.city`) &&
                                touched.experianceDetails?.[index]?.city ?
                                errors.experianceDetails[index].city : ''
                            }
                            touched={touched.experianceDetails?.[index]?.city}
                            variant="outlined"
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormContentInputDiv>
                              <DatePicker
                                label="Joinning Date"
                                inputFormat="dd/MM/yyyy"
                                name={`experianceDetails.${index}.joinningDate`}
                                value={values.experianceDetails[index].joinningDate}
                                onChange={(val) => {
                                  setFieldValue(
                                    `experianceDetails.${index}.joinningDate`,
                                    val,
                                  );
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={
                                        touched.experianceDetails?.[index]?.joinningDate
                                        && Boolean(errors.experianceDetails?.[index]?.joinningDate)
                                      }
                                    />
                                  );
                                }}
                              />
                              <ErrorMessage
                                message={
                                  touched.experianceDetails?.[index]?.joinningDate
                                  && errors.experianceDetails?.[index]?.joinningDate
                                }
                              />
                            </FormContentInputDiv>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormContentInputDiv>
                              <DatePicker
                                label="Till Date"
                                inputFormat="dd/MM/yyyy"
                                name={`experianceDetails.${index}.tillDate`}
                                value={values.experianceDetails[index].tillDate}
                                onChange={(val) => {
                                  setFieldValue(
                                    `experianceDetails.${index}.tillDate`,
                                    val,
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={
                                      touched.experianceDetails?.[index]?.tillDate
                                      && Boolean(errors.experianceDetails?.[index]?.tillDate)
                                    }
                                  />
                                )}
                              />
                              <ErrorMessage
                                message={
                                  touched.experianceDetails?.[index]?.tillDate
                                  && errors.experianceDetails?.[index]?.tillDate
                                }
                              />
                            </FormContentInputDiv>
                            <div />
                            <FormContentInputDiv>
                              <Button
                                component="label"
                                variant='contained'
                                startIcon={<CloudUpload />}
                                sx={{ padding: 2 }}
                              >
                                Experience Letter
                                <input
                                  type='file'
                                  hidden
                                  accept='.jpg,.jpeg,.png,.pdf'
                                  onChange={(event) => {
                                    const file = event?.target?.files[0];
                                    if (file) {
                                      const fileExt = file?.name?.split('.').pop();
                                      const updatedFile = new File([file], `experienceLetter-${index}.${fileExt}`, { type: file.type, endings: file.endings, lastModified: file.lastModified })
                                      setFieldValue(`experianceDetails.[${index}].experienceLetter`, updatedFile);
                                      setFieldTouched(`experianceDetails[${index}].experienceLetter`, true, false);
                                    }
                                  }}
                                />
                              </Button>
                              {experianceDetails[index].experienceLetter && (
                                <List>
                                  <ListItem
                                    onClick={() => handleImagePreview(experianceDetails[index]?.experienceLetter)}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {getFileIcon(experianceDetails[index].experienceLetter)}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={formatFileName(experianceDetails[index].experienceLetter.name)}
                                    />
                                     <ListItemSecondaryAction>
                                      <IconButton
                                      color="error"
                                      onClick={() => setFieldValue(`experianceDetails.[${index}].experienceLetter`, null)}
                                      >
                                      <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              )}
                              {/* Error Message for File */}
                              {touched?.experianceDetails?.[index]?.experienceLetter && errors?.experianceDetails?.[index]?.experienceLetter && (
                                <ErrorMessage message={errors.experianceDetails?.[index]?.experienceLetter} />
                              )}

                            </FormContentInputDiv>
                            <FormContentInputDiv>
                              <Button
                                component="label"
                                variant='contained'
                                startIcon={<CloudUpload />}
                                sx={{ padding: 2 }}
                              >
                                Relieving Letter
                                <input
                                  type='file'
                                  hidden
                                  accept='.jpg,.jpeg,.png,.pdf'
                                  onChange={(event) => {
                                    const file = event?.target?.files[0];
                                    if (file) {
                                      const fileExt = file?.name?.split('.').pop();
                                      const updatedFile = new File([file], `relievingLetter-${index}.${fileExt}`, { type: file.type, endings: file.endings, lastModified: file.lastModified })
                                      setFieldValue(`experianceDetails.[${index}].relievingLetter`, updatedFile);
                                      setFieldTouched(`experianceDetails[${index}].relievingLetter`, true, false);
                                    }
                                  }}
                                />
                              </Button>
                              {experianceDetails[index].relievingLetter && (
                                <List>
                                  <ListItem
                                    onClick={() => handleImagePreview(experianceDetails[index]?.relievingLetter)}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {getFileIcon(experianceDetails[index].relievingLetter)}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={formatFileName(experianceDetails[index].relievingLetter.name)}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton
                                      color="error"
                                      onClick={() => setFieldValue(`experianceDetails.[${index}].relievingLetter`, null)}
                                      >
                                      <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              )}
                              {/* Error Message for File */}
                              {touched?.experianceDetails?.[index]?.relievingLetter && errors?.experianceDetails?.[index]?.relievingLetter && (
                                <ErrorMessage message={errors.experianceDetails?.[index]?.relievingLetter} />
                              )}
                            </FormContentInputDiv>
                            <FormContentInputDiv>
                              <Button
                                component="label"
                                variant='contained'
                                startIcon={<CloudUpload />}
                                sx={{ padding: 2 }}
                              >
                                salary slips
                                <input
                                  type='file'
                                  hidden
                                  accept='.jpg,.jpeg,.png,.pdf'
                                  onChange={(event) => {
                                    const file = event?.target?.files[0];
                                    if (file) {
                                      const fileExt = file?.name?.split('.').pop();
                                      const updatedFile = new File([file], `salarySlip-${index}.${fileExt}`, { type: file.type, endings: file.endings, lastModified: file.lastModified })
                                      setFieldValue(`experianceDetails.[${index}].salarySlip`, updatedFile);
                                      setFieldTouched(`experianceDetails[${index}].salarySlip`, true, false);
                                    }
                                  }}
                                />
                              </Button>
                              {experianceDetails[index].salarySlip && (
                                <List>
                                  <ListItem
                                    onClick={() => handleImagePreview(experianceDetails[index]?.salarySlip)}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {getFileIcon(experianceDetails[index].salarySlip)}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={formatFileName(experianceDetails[index].salarySlip.name)}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton
                                      color="error"
                                      onClick={() => setFieldValue(`experianceDetails.[${index}].salarySlip`, null)}
                                      >
                                      <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              )}
                              {/* Error Message for File */}
                              {touched?.experianceDetails?.[index]?.salarySlip && errors?.experianceDetails?.[index]?.salarySlip && (
                                <ErrorMessage message={errors.experianceDetails?.[index]?.salarySlip} />
                              )}
                            </FormContentInputDiv>
                          </LocalizationProvider>
                        </FromContent>
                      </CardContent>
                    </Card>
                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(ExperianceDetailsInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={expDelDiaOpen} onClose={handleDelExpCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelExpCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelExpConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

const ExperianceDetailsForm = ({ formController }) => {
  const { values, handleChange } = formController;
  const { experianceStatus } = values.personalDetails;
  return (
    <FormWrap>
      <Typography variant="h6">
        Experience
      </Typography>
      <Grid gap={'1rem'}>
        <FormControl>
          <FormLabel>Work Experience</FormLabel>
          <RadioGroup name="personalDetails.experianceStatus" value={experianceStatus} onChange={handleChange} row>
            <FormControlLabel value="experienced" control={<Radio />} label="Experienced" />
            <FormControlLabel value="fresher" control={<Radio />} label="Fresher" />
          </RadioGroup>
        </FormControl>
        {/* experianceStatus === 'experienced' */}
        {experianceStatus === 'experienced' ? experianceDetailsSection() : null}
      </Grid>
    </FormWrap>
  );
};
export default ExperianceDetailsForm;
