import { Chip, Stack } from '@mui/material';
import { Flex } from '../../../../style/utils/Flex.styled';

export const ViewDisChip = (props) => {
  const {
    name,
    value,
    variant,
    icon,
  } = props;

  const simpleChips = {
    backgroundColor: 'transparent',
    color: '#007DB8',
    fontSize: '0.9rem',
  };


  return (
    <Flex dir={'column'} gap={'0.5rem'}>
      <p>{name}</p>
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '0.5rem' }}>
        {Array.isArray(value) === true ? (
          value.map((chip, i) => {
            return (
              <Chip
                key={i + 1}
                label={chip.label}
                color="primary"
                variant="outlined"
                sx={{ borderRadius: '10px', letterSpacing: 1 }}
              />
            );
          })
        ) : (
          <Chip
            icon={icon}
            label={value}
            color="primary"
            variant={variant}
            // sx={{ border: '0' }}
            sx={simpleChips}
          />
        )}
      </Stack>
    </Flex>
  );
};
