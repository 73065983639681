import { Box, Button, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { ButtonWrapper } from '../../../style/components/Button.styled';
import { Flex } from '../../../style/utils/Flex.styled';
import { MyContext } from '../../context/Context';
import { useNavigate } from 'react-router-dom';

const FormFooterButtons = () => {
  const { cPath, spineersLoader } = useContext(MyContext);
  const navigate = useNavigate();
  const navigationPaths = {
    "/other-details": "/professional-details",
    "/professional-details": "/personal-details",
  };
  const handleBackRedirect = () => {
    const previousPath = navigationPaths[cPath];
    if (previousPath) {
      navigate(previousPath);
    }
  }
  return (
    <ButtonWrapper
      disp="flex"
      jc={cPath === '/personal-details' ? 'flex-end' : 'space-between'}
      bg="white"
      pd="1rem"
    >
      {(cPath === "/other-details" || cPath === "/professional-details") && (
        <Button
          disableElevation
          onClick={handleBackRedirect}
        >
          Back
        </Button>
      )}
      <Flex gap="1rem">
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={spineersLoader}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {cPath === '/other-details' ? 'Submit' : 'Save & Next'}
            {spineersLoader && <CircularProgress color="inherit" size={16} />}
          </Box>
        </Button>
      </Flex>
    </ButtonWrapper>
  );
};

export default FormFooterButtons;
