import { createContext, useMemo, useState } from 'react';
import {
  has, isEmpty, isEqual, pick,
} from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import PersonalDetailsItialValues from '../page/personal_details/statistics/personalDetailsInitialValues';
import {
  ExperianceDetailsInit,
  SkillSetInit,
  PortfolioDetailsInit,
} from '../page/professional_details/statistics/professionalDetailsInitialValues';
import {
  BankDetailsInit,
  CoursesDetailsInit,
  EducationDetailsInit,
  EmergencyContactInit,
  OtherPersonalDetailsInit,
} from '../page/other_details/statistics/otherDetailsInitialValues';
import {
  getPersonalDetsApi,
  getProfessionalDetsApi,
  getOtherDetsApi,
  loginApi,
  logoutApi,
  otherDetsApi,
  personalDetsApi,
  professionalDetsApi,
  getAppDetsApi,
  getAppLogApi,
  viewOnBoardAppApi,
  appStatuUpdateApi,
  adminCandidateViewApi,
  isLoginApi,
  fetchDegreesApi,
  fetchStreamApi,
  addDegreeApi,
  dropdownOptForCanAppAPI,
} from '../../api/apis';
import showSnackbar from '../components/message/showSnackbar';
import axios from 'axios';

const MyContext = createContext();
// Provider
const MyProvider = ({ children }) => {
  const id = {
    applicationId: localStorage.getItem('applicationId'),
    candidateId: localStorage.getItem('candidateId'),
  };
  /** @ Updated context  */

  /**
   * @ Application handling
   * - page navigation
   * - global error
   */
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const [globalError, setGlobalError] = useState({
    isError: false,
    status: 404,
    title: 'Page not found',
    code: 'connection',
    message: 'Lost connection',
  });

  const [appStatus, setAppStatus] = useState(null);
  const [appSubmitCount, setAppSubmitCount] = useState(0);
  const [unSavedAppData, setUnSaveAppData] = useState(null);

  /** Handle failure response  */
  const handleFailureResponse = (props) => {
    const {
      status, location, code, message,
    } = props;

    if (isEmpty(status, 0)) navigate(location);
    if (isEqual(status, 403)) navigate(location);

    setGlobalError((preVal) => {
      return {
        ...preVal,
        isError: true,
        status,
        code,
        message,
      };
    });
  };

  /**
   * @ user auth
   * - isLogin
   * - login
   * - logout
   */

  const loginFormInitialValues = {
    email: '',
    password: '',
  };

  const [loginError, setLoginError] = useState();

  const userLoginFunc = async (props) => {
    const data = props;
    const loginApiRes = await loginApi(data);
    const {
      success, status, location, message,
    } = loginApiRes;
    if (success && status === 200) {
      showSnackbar(message, { variant: 'success' });
      navigate(location);
    }
    if (!success) {
      setLoginError(message);
      showSnackbar(message, { variant: 'error' });
    }
  };

  const userLogoutFunc = async () => {
    const logoutApiRes = await logoutApi();
    const { success, status, location } = logoutApiRes;
    if (success && status === 200) {
      localStorage.clear();
      navigate(location);
    }
  };

  /**
   * @ onboarding application
   * 1) Application useState
   *  - Application details
   */

  const [applicationDetails, setApplicationDetails] = useState({});
  const [applicationDetailsLog, setApplicationDetailsLog] = useState([]);
  const [applicationDetailLoading, setApplicationDetailsLoading] = useState(false);

  const getApplicationDetailsFun = async () => {
    setApplicationDetailsLoading(true);
    const appDetailsRes = await getAppDetsApi();

    const { success } = appDetailsRes;

    if (!success) {
      handleFailureResponse(
        pick(appDetailsRes, ['status', 'location', 'code', 'message']),
      );
    }

    if (success) {
      setApplicationDetails(appDetailsRes.data);
    }
    setApplicationDetailsLoading(false);
  };

  const getApplicationLogFun = async () => {
    const appLogRes = await getAppLogApi();
    const { success } = appLogRes;
    if (!success) {
      handleFailureResponse(
        pick(appLogRes.data, ['status', 'location', 'code', 'message']),
      );
    }
    if (success) {
      setApplicationDetailsLog(appLogRes.data);
    }
  };

  // const applicationData = dummyApplication;

  const [candidateAvatar, setCandidateAvatar] = useState(null);
  const [candidatePassportPhoto, setCandidatePassportPhoto] = useState(null);
  const [candidateBankDoc, setCandidateBankDoc] = useState(null);

  const [personalDetails, setPersonalDetails] = useState({
    ...PersonalDetailsItialValues,
  });
  const [selectedAadharCardFiles, setSelectedAadharCardFiles] = useState([]);
  const [aadharCardValMessage, setAadharCardValMessage] = useState("");

  const [professionalDetails, setProfessionalDetails] = useState({
    personalDetails: { experianceStatus: '' },
    experianceDetails: [{ ...ExperianceDetailsInit }],
    skillSet: { ...SkillSetInit },
    portfolioDetails: [{ ...PortfolioDetailsInit }],
  });
  const [otherDetails, setOtherDetails] = useState({
    educationDetails: [{ ...EducationDetailsInit }],
    coursesDetails: [{ ...CoursesDetailsInit }],
    bankDetails: { ...BankDetailsInit },
    emergencyContactDetails: [{ ...EmergencyContactInit }],
    personalDetails: { ...OtherPersonalDetailsInit },
  });

  /* View Application handle */

  const [onBoardApplication, setOnBoardApplication] = useState({});

  const getOnBoardApplicationDetails = async (props) => {
    const viewOnBoardAppAPICall = await viewOnBoardAppApi(props);
    const { data } = viewOnBoardAppAPICall;
    setOnBoardApplication(data);
  };

  const viewOnBoardApplicationHandleFunc = async (props) => {
    // await getOnBoardApplicationDetails(props);
    navigate('/application');
  };

  const editOnBoardApplicationFunc = async (appStatus) => {
    if (appStatus === 'rejected' || appStatus === 'delivered') {
      const appStatusData = {
        applicationStatus: 'started',
        note: 'candidate has started the onboarding process.',
      };
      await appStatuUpdateApi(appStatusData);
    }
    navigate('/personal-details');
  };

  const isUserLoginFunc = async () => {
    const isUserLoginApi = await isLoginApi();
    const { success, status, location } = isUserLoginApi;
    if (!success && status === 500) {
      setGlobalError(true);
      navigate('/error');
    }
    if (!success && status === 403) {
      navigate(location);
    }
  };

  const proceedOnBoardApplicationFunc = async (appStatus) => {
    if (appStatus === 'created' || appStatus === 'delivered') {
      const appStatusData = {
        applicationStatus: 'started',
        note: 'candidate has started the onboarding process.',
      };
      await appStatuUpdateApi(appStatusData);
    }
    navigate('/personal-details');
  };
  // Function to get the file extension based on content type
  function getFileExtension(contentType) {
    switch (contentType) {
      case "image/png":
        return "png";
      case "image/jpeg":
        return "jpg";
      case "application/pdf":
        return "pdf";
      default:
        return "";
    }
  }

  // Function to fetch and create a file from a URL
  async function fetchAndCreateFile(url) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const contentType = response.headers["content-type"];
      const extension = getFileExtension(contentType);
      const filename = `${url.split('/').pop().split('-')[0]}.${extension}`;
      return new File([response.data], filename, { type: contentType });
    } catch (error) {
      throw error;
    }
  }
  async function fetchAndCreateExpFile(url) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const contentType = response.headers["content-type"];
      const fileUrlIndex = url.lastIndexOf('/');
      const filename = url.substring(fileUrlIndex + 1)
      return new File([response.data], filename, { type: contentType });
    } catch (error) {
      throw error;
    }
  }

  // Process the aadharImages array and create files
  async function processAadharImages(aadharImages) {
    const files = await Promise.all(
      aadharImages.map(url => fetchAndCreateFile(url))
    );
    return files;
  }



  /** Hanlde Candidate personal details */
  const getCandidatePersonalDetailsFunc = async () => {
    setLoadingData(true);
    const personalDetailsRes = await getPersonalDetsApi();
    if (personalDetailsRes.success) {
      setPersonalDetails(personalDetailsRes.data);
      setAppStatus(personalDetailsRes.data?.appStatus);
      setAppSubmitCount(personalDetailsRes.data?.submitCount);
      processAadharImages(personalDetailsRes.data.aadharImages).then(files => {
        setSelectedAadharCardFiles(files);
        // Use the files array as needed
      }).catch(error => {
      });
      setLoadingData(false);
    }
    if (!personalDetailsRes.success) {
      setPersonalDetails({
        ...PersonalDetailsItialValues,
      });
      enqueueSnackbar(personalDetailsRes.message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setLoadingData(false);
      navigate('/');
    }
  };

  const handleOnBoardApplicationPersonalDetails = async (props) => {
    if (selectedAadharCardFiles.length === 0) {
      setAadharCardValMessage("Addharcard is required!");
      setSpineerLoader(false);
      return;
    }
    const data = new FormData();
    const avatar = candidateAvatar !== null ? candidateAvatar : null;
    const passport = candidatePassportPhoto;
    const body = JSON.stringify(props);

    data.append('profile', avatar);
    data.append('passport', passport);
    if (selectedAadharCardFiles.length > 0) {
      data.append('aadharFront', selectedAadharCardFiles[0]);
      data.append('aadharBack', selectedAadharCardFiles[1]);
    }
    data.append('form', body);

    const personalDetsApiRes = await personalDetsApi(data);

    return personalDetsApiRes;
  };

  /** Hanlde Candidate profesinol details */
  const getCandidateProfessionalDetailsFunc = async () => {
    setLoadingData(true);
    const professionalDetsRes = await getProfessionalDetsApi();
    const { success, message } = professionalDetsRes;
    if (success) {
      const { data } = professionalDetsRes;
      setAppStatus(data?.appStatus);
      setAppSubmitCount(data?.submitCount);
      if (data?.experianceDetails) {
        for (const item of data.experianceDetails) {
          if (item.experienceLetter) {
            item.experienceLetterUrl = item.experienceLetter;
            item.experienceLetter = await fetchAndCreateExpFile(item.experienceLetter)
          }
          if (item.relievingLetter) {
            item.relievingLetterUrl = item.relievingLetter;
            item.relievingLetter = await fetchAndCreateExpFile(item.relievingLetter)
          }
          if (item.salarySlip) {
            item.salarySlipUrl = item.salarySlip;
            item.salarySlip = await fetchAndCreateExpFile(item.salarySlip)
          }
        }
      }
      setProfessionalDetails((prevProps) => {
        return {
          ...prevProps,
          personalDetails: isEmpty(data.personalDetails)
            ? { experianceStatus: '' }
            : data.personalDetails,

          experianceDetails: isEmpty(data.experianceDetails)
            ? [{ ...ExperianceDetailsInit }]
            : data.experianceDetails,

          skillSet: isEmpty(data.skillSet)
            ? { ...SkillSetInit }
            : data.skillSet,

          portfolioDetails: isEmpty(data.portfolioDetails)
            ? [{ ...PortfolioDetailsInit }]
            : data.portfolioDetails,
        };
      });
    }
    if (!success) {
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      navigate('/');
    }
    setLoadingData(false);
  };

  const handleOnBoardApplicationProfessionalDetails = async (props) => {
    const formData = new FormData();
    props.experianceDetails.map((item, index) => {
      formData.append("experienceLetter", item.experienceLetter)
      formData.append("relievingLetter", item.relievingLetter)
      formData.append("salarySlip", item.salarySlip)
    })
    const body = JSON.stringify(props);
    formData.append('form', body);
    try {
      // Call the API with FormData
      const professionalDetailsRes = await professionalDetsApi(formData);
      return professionalDetailsRes;
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  /** Hanlde Candidate other details */
  const getCandidateOtherDetailsFunc = async () => {
    setLoadingData(true);
    const otherDetsRes = await getOtherDetsApi();
    const { success, message } = otherDetsRes;
    if (success) {
      const { data } = otherDetsRes;
      setAppStatus(data?.appStatus);
      setAppSubmitCount(data?.submitCount);
      setOtherDetails((prevProps) => {
        return {
          ...prevProps,
          personalDetails: isEmpty(data.personalDetails)
            ? { ...OtherPersonalDetailsInit }
            : data.personalDetails,
          educationDetails: isEmpty(data.educationDetails)
            ? [{ ...EducationDetailsInit }]
            : data.educationDetails,
          coursesDetails: isEmpty(data.coursesDetails)
            ? [{ ...CoursesDetailsInit }]
            : data.coursesDetails,
          bankDetails: isEmpty(data.bankDetails)
            ? { ...BankDetailsInit }
            : data.bankDetails,
          emergencyContactDetails: isEmpty(data.emergencyContactDetails)
            ? [{ ...EmergencyContactInit }]
            : data.emergencyContactDetails,
        };
      });
    }
    if (!success) {
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      navigate('/');
    }
    setLoadingData(false);
  };

  const [spineerLoader, setSpineerLoader] = useState(false);
  const handleOnBoardApplicationOtherDetails = async (props) => {
    const data = new FormData();
    const bankDoc = candidateBankDoc !== null ? candidateBankDoc : null;
    const body = JSON.stringify(props);
    data.append('document', bankDoc);
    data.append('form', body);

    const saveOtherDetsApiRes = await otherDetsApi(data);
    const { success, location, message } = saveOtherDetsApiRes;
    if (success) {
      setSpineerLoader(false);
      navigate(location);
    }
    if (!success) {
      setSpineerLoader(false);
      return saveOtherDetsApiRes;
      // if (has(message, ['alreadyInPendingList'])) navigate(location);
    }
  };

  /** @ Admin Application view */
  const examiningCandidateOnBoardApplication = async (props) => {
    const verifyAppKeyApi = await adminCandidateViewApi(props);
    const { data } = verifyAppKeyApi;
    if (data) {
      setPersonalDetails(data.candidateDetails);
      if (data?.professionalDetails?.experianceDetails) {
        for (const item of data?.professionalDetails?.experianceDetails) {
          if (item.experienceLetter) {
            item.experienceLetterUrl = item.experienceLetter;
            item.experienceLetter = await fetchAndCreateExpFile(item.experienceLetter)
          }
          if (item.relievingLetter) {
            item.relievingLetterUrl = item.relievingLetter;
            item.relievingLetter = await fetchAndCreateExpFile(item.relievingLetter)
          }
          if (item.salarySlip) {
            item.salarySlipUrl = item.salarySlip;
            item.salarySlip = await fetchAndCreateExpFile(item.salarySlip)
          }
        }
      }

      setProfessionalDetails(data.professionalDetails);
      setOtherDetails(data.otherDetails);
    }
  };

  // Define a state to hold dropdown options for candidate applications
  const [dropdownOptForCanApp, setDropdownOptForCanApp] = useState([]);

  /**
   * Fetches the dropdown options for candidate applications from the API.
   * Auther: @hitesh kanjariya
   * - Makes an asynchronous API call using `dropdownOptForCanAppAPI`.
   * - If the call is successful (status 200), updates the state with the received data.
   * - Handles errors gracefully by displaying a notification using `enqueueSnackbar`.
   */
  const getDropdownOptForCanApp = async () => {
    try {
      // Call the API to fetch dropdown options
      const res = await dropdownOptForCanAppAPI();

      // Check if the response is successful
      if (res.status === 200) {
        // Update the state with the fetched data
        setDropdownOptForCanApp(res?.data?.data);
      }
    } catch (error) {
      // Display an error notification if the API call fails
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 5000, // Notification disappears after 5 seconds
      });
    }
  };




  /**
   * @ useMemo()
   */
  const val = useMemo(() => ({
    isUserLogin: isUserLoginFunc,
    error: globalError,
    cPath: currentPath,
    loginInitVal: loginFormInitialValues,
    userLogin: userLoginFunc,
    loginErr: loginError,
    userLogout: userLogoutFunc,
    loading: loadingData,
    setLoading: setLoadingData,
    applicationDets: applicationDetails,
    appDetails: getApplicationDetailsFun,
    applicationDetailLoading: applicationDetailLoading,
    appLog: applicationDetailsLog,
    appLogApi: getApplicationLogFun,
    proceedOnBoard: proceedOnBoardApplicationFunc,
    personalDets: personalDetails,
    setPersonalDets: setPersonalDetails,
    professionalDets: professionalDetails,
    setProfessionalDets: setProfessionalDetails,
    otherDets: otherDetails,
    setOtherDets: setOtherDetails,
    setSpineersLoader: setSpineerLoader,
    spineersLoader: spineerLoader,
    setAvatar: setCandidateAvatar,
    setCandidatePassportPhoto: setCandidatePassportPhoto,
    candidatePassportPhoto: candidatePassportPhoto,
    candidasteAvatar: candidateAvatar,
    setBankDoc: setCandidateBankDoc,
    candidatesBankDoc: candidateBankDoc,
    savePersonalDetails: handleOnBoardApplicationPersonalDetails,
    saveProfessionalDetails: handleOnBoardApplicationProfessionalDetails,
    saveOtherDetails: handleOnBoardApplicationOtherDetails,
    viewOnBoardApp: viewOnBoardApplicationHandleFunc,
    editApplication: editOnBoardApplicationFunc,
    getOnBoardAppDets: getOnBoardApplicationDetails,
    appIds: id,
    getPersonalDetails: getCandidatePersonalDetailsFunc,
    getProfessionalDetails: getCandidateProfessionalDetailsFunc,
    getCandidateOtherDetails: getCandidateOtherDetailsFunc,
    onBoardApp: onBoardApplication,
    examiningCandidateOnBoardApp: examiningCandidateOnBoardApplication,
    selectedAadharCardFiles: selectedAadharCardFiles,
    setSelectedAadharCardFiles: setSelectedAadharCardFiles,
    aadharCardValMessage: aadharCardValMessage,
    setAadharCardValMessage: setAadharCardValMessage,
    dropdownOptForCanApp,
    getDropdownOptForCanApp,
    appStatus,
    appSubmitCount,
    unSavedAppData,
    setUnSaveAppData,
  }));

  return <MyContext.Provider value={val}>{children}</MyContext.Provider>;
};
export { MyContext, MyProvider };
