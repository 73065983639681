const ExperianceDetailsInit = {
  companyName: '',
  jobTitle: '',
  city: '',
  joinningDate: null,
  tillDate: null,
  experienceLetter: null,
  relievingLetter: null,
  salarySlip: null,
};

const SkillSetInit = {
  skills: [],
  tools: [],
};

const PortfolioDetailsInit = {
  title: '',
  link: '',
  description: '',
};

export { ExperianceDetailsInit, SkillSetInit, PortfolioDetailsInit };
