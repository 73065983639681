// Import necessary modules and components
import { useContext } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import { Grid } from '../../../../style/utils/Grid.styled';
import { MyContext } from '../../../context/Context';
import {
  FormWrap,
  FromContent,
} from '../../../../style/components/form/StyledForm.styled';
import { ImagePopup } from '../../../components/img/ImagePopup';
import CustomTextField from '../../../components/form/formControl/TextField';
import ErrorMessage from '../../../components/message/ErrorMessage';
import defaultDocImage from '../../../../imgs/default/default-doc-image.jpg';

const BankDetailsForm = ({ formController }) => {
  // Use context to get and set bank document details
  const { setBankDoc, candidatesBankDoc } = useContext(MyContext);
  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
  } = formController;
  console.log('🚀 ~ BankDetailsForm ~ errors:', errors);
  return (
    <FormWrap>
      <Typography variant="h6">Bank Details</Typography>
      <FromContent>
        <CustomTextField
          label="Bank Name"
          name="bankDetails.bankName"
          value={values.bankDetails.bankName}
          onChange={handleChange}
          errors={errors.bankDetails && errors.bankDetails.bankName}
          touched={touched.bankDetails && touched.bankDetails.bankName}
          variant="outlined"
        />
        <CustomTextField
          label="Branch"
          name="bankDetails.branch"
          value={values.bankDetails.branch}
          onChange={handleChange}
          errors={errors.bankDetails && errors.bankDetails.branch}
          touched={touched.bankDetails && touched.bankDetails.branch}
          variant="outlined"
        />
        <CustomTextField
          label="Account Holder Name"
          name="bankDetails.accountHolder"
          value={values.bankDetails.accountHolder}
          onChange={handleChange}
          errors={errors.bankDetails && errors.bankDetails.accountHolder}
          touched={touched.bankDetails && touched.bankDetails.accountHolder}
          variant="outlined"
        />
        <CustomTextField
          type="number"
          label="Account No."
          name="bankDetails.accountNo"
          value={values.bankDetails.accountNo}
          onChange={handleChange}
          errors={errors.bankDetails && errors.bankDetails.accountNo}
          touched={touched.bankDetails && touched.bankDetails.accountNo}
          variant="outlined"
        />
        <CustomTextField
          label="IFSC No."
          name="bankDetails.ifscNo"
          value={values.bankDetails.ifscNo}
          onChange={handleChange}
          errors={errors.bankDetails && errors.bankDetails.ifscNo}
          touched={touched.bankDetails && touched.bankDetails.ifscNo}
          variant="outlined"
        />
        <Grid colSpan={'1 / span 3'}>
          <FormControl>
            <FormLabel
              sx={{
                color:
                  errors &&
                  errors.bankDetails &&
                  errors.bankDetails.attachment &&
                  'error.main',
              }}
            >
              {((values.bankDetails.document === null && candidatesBankDoc === null) || (errors.bankDetails && errors.bankDetails.attachment)) && (
                <ImagePopup
                img={defaultDocImage}
                alt={'bank document'}
                skeletonVariant="rectangular"
                css="image-thumbnail-2"
                isOpenInNewtab
                />
              )}

              {!candidatesBankDoc && values.bankDetails.document && (
                <span className="avatar-thumbnail-span">
                  <ImagePopup
                    img={values.bankDetails.document}
                    alt={'bank document'}
                    skeletonVariant="rectangular"
                    css="image-thumbnail-2"
                    isOpenInNewtab
                  />
                </span>
              )}

              {candidatesBankDoc &&
                errors &&
                (!errors.bankDetails || !errors.bankDetails.attachment) && (
                  <span className="avatar-thumbnail-span">
                    <ImagePopup
                      img={URL.createObjectURL(candidatesBankDoc)}
                      alt={'bank document'}
                      skeletonVariant="rectangular"
                      css="image-thumbnail-2"
                      isOpenInNewtab
                    />
                  </span>
                )}
            </FormLabel>
            <Button
              variant="contained"
              component="label"
              sx={{ maxWidth: '222px', mt: '0.75rem' }}
              disableElevation
            >
              Upload
              <input
                hidden
                type="file"
                name="bankDetails.attachment"
                id="bankDetails.attachment"
                // value={values.bankDetails.attachment}
                accept='.jpg,.jpeg,.png'
                onChange={(e) => {
                  setFieldValue('bankDetails.attachment', e.target.files[0]);
                  setBankDoc(e.target.files[0]);
                }}
              />
            </Button>
            <div style={{ marginTop: '12px', marginBottom: '8px' }}>
              <ErrorMessage
                message={
                  touched?.bankDetails?.attachment && errors?.bankDetails?.attachment
                    ? errors.bankDetails.attachment
                    : ''
                }
              />
            </div>
            <FormLabel>Passbook or cancelled cheque images</FormLabel>
          </FormControl>
        </Grid>
      </FromContent>
    </FormWrap>
  );
};

export default BankDetailsForm;
