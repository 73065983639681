import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  Divider,
  Skeleton,
} from '@mui/material';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { pick } from 'lodash';
import { MyContext } from '../../context/Context';
import { Section } from '../../../style/layout/Section.styled';
import {
  AllCardWrap,
  CardActionFooter,
  CardContainer,
  CardHead,
  CardTopbar,
  DisplayFlex,
} from '../../../style/components/card.styled';

const Home = () => {
  const {
    viewOnBoardApp,
    proceedOnBoard,
    appDetails,
    applicationDets,
    applicationDetailLoading,
  } = useContext(MyContext);

  if (appDetails !== '' && appDetails !== undefined) {
    localStorage.setItem('applicationId', appDetails.applicationId);
    localStorage.setItem('candidateId', appDetails.candidateId);
  }

  useEffect(() => {
    appDetails();
  }, []);

  const applicationStatusChips = (props) => {
    const capitalizeFirstLetter = (str) => {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const capitalizedLabel = capitalizeFirstLetter(props);

    if (props === 'created') {
      return <Chip icon={<FactCheckOutlinedIcon />} label={capitalizedLabel} />;
    }
    if (props === 'started') {
      return <Chip icon={<StartOutlinedIcon />} label={capitalizedLabel} />;
    }

    if (props === 'submitted') {
      return <Chip icon={<FactCheckOutlinedIcon />} label={capitalizedLabel} />;
    }

    if (props === 'approved') {
      return <Chip icon={<VerifiedIcon />} label={capitalizedLabel} />;
    }

    if (props === 'rejected') {
      return <Chip icon={<CancelOutlinedIcon />} label={capitalizedLabel} />;
    }

    if (props === 'archived') {
      return <Chip icon={<ArchiveOutlinedIcon />} label={capitalizedLabel} />;
    }
    if (props === 'restored') {
      return <Chip icon={<RestoreOutlinedIcon />} label={capitalizedLabel} />;  // New 'restored' case
    }
    // if (props === 'delivered') {
    //   return <Chip icon={<DoneAllIcon />} label={props.toUpperCase()} />;
    // }
    return null;
  };

  const applicationStatusButtons = (appStatus) => {
    if (appStatus === 'created' || appStatus === 'delivered') {
      return (
        <Button size="small" onClick={() => proceedOnBoard(appStatus)}>
          Start onboarding
        </Button>
      );
    }
    if (appStatus === 'started') {
      return (
        <Button size="small" onClick={() => proceedOnBoard(appStatus)}>
          Continue
        </Button>
      );
    }
    if (
      appStatus === 'approved' ||
      appStatus === 'rejected' ||
      appStatus === 'submitted' ||
      appStatus === 'restored'
    ) {
      return (
        <Button
          size="small"
          onClick={() => viewOnBoardApp(pick(appDetails, ['applicationId', 'candidateId']))}
        >
          View
        </Button>
      );
    }
    return null;
  };

  const alertMessage = (props) => {
    if (props === 'rejected') {
      return (
        <Alert severity="error" mb="1rem">
          <AlertTitle>Error</AlertTitle>
          This is an error alert — check it out!
        </Alert>
      );
    }
    return null;
  };

  return (
    <Section>
      {alertMessage()}
      {applicationDetailLoading ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} height={30} width={100} />
          <DisplayFlex>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={400} height={40} />
          </DisplayFlex>
          <DisplayFlex>
            <Skeleton variant="rectangular" width={210} height={50} />
            <Skeleton variant="rectangular" width={210} height={50} />
          </DisplayFlex>
        </>
      ) : (
        <AllCardWrap>
          <CardContainer variant="outlined" brdbtm="true" status={'created'}>
            <CardTopbar>{applicationDets.createdAt}</CardTopbar>
            <CardHead
              avatar={(
                <Avatar>
                  {applicationDets.firstname && applicationDets.firstname.slice(0, 1).toUpperCase()}
                  {applicationDets.lastname && applicationDets.lastname.slice(0, 1).toUpperCase()}
                </Avatar>
              )}
              title={`${applicationDets.firstname} ${applicationDets.lastname}`}
              subheader={applicationDets.applicationTag}
            />
            <Divider />
            <CardActionFooter>
              {applicationStatusChips(applicationDets.applicationStatus)}
              {applicationStatusButtons(applicationDets.applicationStatus)}
            </CardActionFooter>
          </CardContainer>
        </AllCardWrap>
      )}
    </Section>
  );
};

export default Home;
