import * as Yup from 'yup';

const otherDetailsValidationSchema = Yup.object({
  bankDetails: Yup.object().shape({
    bankName: Yup.string()
      .required('Bank name is required.')
      .matches(/\S+/, 'Bank name cannot be blank'),
    branch: Yup.string()
      .required('Branch name is required.')
      .matches(/\S+/, 'Branch name cannot be blank'),
    accountHolder: Yup.string()
      .required('Account holder name is required.')
      .matches(/\S+/, 'Account holder name cannot be blank'),
    accountNo: Yup.string()
      .nullable()
      .required('Account number is required.')
      .matches(/^\d+$/, 'Account number must be numeric'),
    ifscNo: Yup.string()
      .nullable()
      .required('IFSC code is required.')
      .matches(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/, 'Invalid IFSC code format'),
    attachment: Yup.mixed()
      .test({
        message: 'Passbook or cancelled cheque images is required',
        test: (file, context) => {
          if (
            !context.from[context.from.length - 1].value.bankDetails.document &&
            !file
          ) {
            return false;
          }
          return true;
        },
      })
      .test({
        message: 'You can Only Upload PNG/JPG/JPEG',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          if (file) {
            return !(file && !file.name.match(/\.(jpg|jpeg|png)$/));
          }
        },
      })
      .test({
        message: 'More than 2MB size image is not allowed',
        test: (file) => {
          if (!file) {
            return true;
          }
          return file.size <= 2 * 1024 * 1024;
        },
      }),
  }),

  educationDetails: Yup.array(
    Yup.object({
      stream: Yup.string()
        .transform(value => (value && value.value ? value.value : value))
        .nullable()
        .required('Stream selection is required.'),
      degree: Yup.string()
        .transform(value => (value && value.value ? value.value : value))
        .nullable()
        .required('Degree selection is required.'),
      institute: Yup.string()
        .required('Institute name is required.')
        .matches(/\S+/, 'Institute name cannot be blank')
      ,
      boardOrUniversity: Yup.string()
        .required('Board or University name is required.')
        .matches(/\S+/, 'Board or University name cannot be blank'),
      gpaOrPercentage: Yup.string()
        .required('GPA or Percentage is required.')
        .matches(/\S+/, 'GPA or Percentage cannot be blank'),
      passingYear: Yup.date()
        .required('Year of Passing is required.')
        .nullable(),
      // .max(new Date(), 'Year of Passing cannot be in the future'),
    }),
  ),
  emergencyContactDetails: Yup.array(
    Yup.object().shape({
      fullname: Yup.string()
        .required('Full name is required.')
        .matches(/\S+/, 'Full name cannot be blank or contain only spaces'),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Contact number must be exactly 10 digits.')
        .required('Contact number is required.'),
      relation: Yup.string()
        .transform(value => (value && value.value ? value.value : value))
        .nullable()
        .required('Relation selection is required.'),
      city: Yup.string()
        .required('City is required.')
        .matches(/\S+/, 'City cannot be blank or contain only spaces'),
    })
  ),
});
export default otherDetailsValidationSchema;
