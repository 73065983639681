//  Tab style

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from '../utils/Flex.styled';

// React Router's Link tag style
export const FromTabWrap = styled.div`
  display: flex;
  gap: 1rem;
`;
// Custom Tab styled component
export const Tab = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.primaryLight : theme.color.lightes};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.primary : theme.color.disable};
  padding: 1rem;
  border-bottom: 5px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.primary : theme.color.disable};
  border-radius: ${({ theme }) => theme.radius};
  overflow: hidden;

  & > div {
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.primary : theme.color.disable};
  }

  @media only screen and (max-width: 500px) {
    width: 20%;
    padding: 0.5rem;

    .hide {
      display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    }
  }
`;

export const LinkTab = styled(NavLink)`
  width: 100%;
  background-color: ${({ theme }) => theme.color.lightes};
  color: ${({ theme }) => theme.color.disable};
  padding: 1rem;
  border-bottom: 5px solid ${({ theme }) => theme.color.disable};
  border-radius: ${({ theme }) => theme.radius};
  overflow: hidden;

  &.active {
    background-color: ${({ theme }) => theme.color.primaryLight};
    color: ${({ theme }) => theme.color.primary};
    border-bottom: 5px solid ${({ theme }) => theme.color.primary};

    div {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media only screen and (max-width: 500px) {
    width: 20%;
    padding: 0.5rem;

    .hide {
      display: none;
    }

    &.active {
      width: 100%;
    }

    &.active .hide {
      display: block;
    }
  }
`;

export const FromTabNumber = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 500px) {
    & > h5 {
      font-size: 0.8rem;
    }
  }
`;

export const FromTabTitle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  & > h4 {
    font-size: 1rem;
  }
  @media only screen and (max-width: 500px) {
    gap: 0.5rem;
  }
`;

export const TabIcon = styled(Flex)`
  color: ${({ theme }) => theme.color.disable};
  font-size: 1.2rem;

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;
